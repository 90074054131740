.pagination {
  flex: 0 0 auto;
  display: flex;
  font-size: 20;
  justify-content: flex-end;
  margin: 30px 20px;
  align-items: center;

  button {
    background-color: #fafafb;
    border-radius: 10px;
    // width: 100px;
    padding: 7px 12px;
    margin: 15px;
    border-color: #e5e7eb;
  }
  button.active {
    background-color: #f6552d;
    color: white;
  }
  span {
    margin: 15px;
    font-size: 14px;
    //font-weight: bold;
    color: #92929d;
  }
  span.active {
    color: #f6552d;
  }

  @media screen and (max-width: 768px) {
    .pagination {
      font-size: 18px;
      height: auto;
      justify-content: center;
    }
  }
}
.phone-record {
  display: flex;
  justify-content: center;
}
.no-data {
  display: block;
  margin: auto;
  margin-top: 10%;
  img {
    display: block;
    margin: auto;
    // margin-top: 10%;
  }
  p {
    text-align: center;
    color: #fb6d4a;
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: capitalize;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top:hover {
  background-color: #0056b3;
}

// .pagination {
//   display: flex;
//   justify-content: flex-end;
//   margin-top: 40px;
//   margin-right: 12px;

//   .pagi {
//     background-color: #19202c !important;
//   }
// }
