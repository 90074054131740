.comments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-bottom: 20px;
  width: 100%;
}

.comments-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.comments-container {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 10px;

  @media screen and (min-width: 800px) {
    margin-bottom: 120px;
  }
}
.comments-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.comment-box {
  display: flex;
  font-family: "Inter", sans-serif;
  gap: 10px;
  width: 100%;
}
.publisher {
  color: #030303;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.3px;
}
.date {
  color: #4b587c;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.comment-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #4b587c;
  font-weight: 400;
  font-size: 17px;
}

.comment-info {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 5px;
}
.comment-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.comment-actions {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.dislike-comment,
.like-comment {
  display: inline;
  display: flex;
  align-items: center;
}
.comment-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #030303;
}
.text {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: left !important;
  color: #aaaaaa !important;
  font-family: "Roboto" !important;
}
.input-container {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 10px 0 20px 0px;
  background-color: #f8f8f8;
  border-radius: 19px;
  padding: 15px 15px;
  position: sticky;
  bottom: 15px;
  width: 100%;
  z-index: 2;
  margin-bottom: 100px;

  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }

  input {
    color: #00000099;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.03em;
    background-color: #f8f8f8;
    width: 100%;
    height: 30px;
    outline: none;
  }
}
.avatar {
  font-size: 12px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.send-icon {
  cursor: pointer;
}
.comment {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
