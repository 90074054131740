.forgot-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/background-success.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .forgot-content {
    background-color: #fff;
    border-radius: 30px;
    padding: 30px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      text-align: center;
      color: #e86747;
      font-weight: 700;
      font-size: 32px;
    }

    p {
      text-align: center;
      color: #4a4a4a;
      font-size: 18px;
    }

    .have-password {
      margin-top: 10px;
      text-align: center;

      button {
        text-decoration: underline;
      }
    }

    .received-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      text-align: center;

      img {
        width: 60px;
      }
    }
  }
}
