.slider-section {
  border-radius: 8px;
  background: #fdf3ef;
  padding: 40px;
  margin: 25px;
  min-height: 260px;
  .MuiSlider-root {
    color: #ff623e !important;
  }

  .MuiSlider-rail {
    height: 4px !important;
    opacity: 1 !important;
    background-color: #ccc !important;
    position: relative !important;
  }

  .MuiSlider-track {
    height: 4px !important;
  }

  .MuiSlider-thumb {
    height: 16px !important;
    width: 16px !important;
    background-color: #ff623e !important;
    border: 2px solid currentColor !important;
    margin-top: -6px !important;
    margin-left: -8px !important;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) !important;
    &:focus,
    &:hover,
    &.Mui-active {
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2) !important;
    }
  }

  .MuiSlider-mark {
    height: 8px !important;
    width: 1px !important;
    background-color: #000 !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .MuiSlider-markLabel {
    top: 24px !important;
    font-size: 0.75rem !important;
  }
}

.frame-label {
  text-align: center;
  border-radius: 8px;
  padding: 20px;
  border: #ff623e;
  box-shadow: #ccc;
  display: center;
}

.frame-image {
  height: 45px;
  max-width: 34px;
  margin-left: 40px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  object-fit: cover;
}

.operation-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .operations {
    display: flex;
    gap: 20px;
  }

  .duration-video {
    color: #191918;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}
.video {
  border-radius: 18px !important;
  overflow: hidden;
}
