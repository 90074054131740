.video-page {
  overflow-x: hidden;
  width: 100%;
  .video-container {
    // min-height: 632px;
    position: relative;
    width: 100% !important;
    min-width: 100% !important;

    .video-player {
      width: 100% !important;
      height: 100% !important;
      background-color: #edeef4;
      min-width: 1000px !important;
      .video-react-video {
        min-width: 100% !important;
      }
    }
    video {
      min-width: 98% !important;
    }
    .edit-buttons {
      position: absolute;
      border-radius: 50%;
      border: 1px solid #f1f1f1;
      background: #fff;
      box-shadow: 0px 5.5px 13.75px 0px rgba(0, 0, 0, 0.1);
      padding: 14px;
      z-index: 100000;
      display: flex;
      flex-direction: column;
      gap: 20px;
      right: 20px;
      top: 50%;

      span {
        cursor: pointer;
      }
    }
  }

  .video-info {
    margin: 32px;
    padding: 30px 24px;
    background-color: #f8f9fb;
    border-radius: 6px;

    .info-header {
      display: flex;
      gap: 20px;
      align-items: center;

      .avatar-container {
        img {
          width: 62px;
          height: 62px;
          object-fit: fill;
          border-radius: 100%;
        }
      }

      .info-user-name {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .video-name {
          color: #111;
          font-family: "Inter", sans-serif;
          font-size: 28px;
          font-weight: 700;
          letter-spacing: -0.714px;
        }

        .user-name {
          color: rgba(15, 23, 42, 0.5);
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .form-container {
      background: #fff;
      padding: 17px 17px 30px 17px;
      width: 100%;
      border-radius: 5px;
      margin-top: 22px;

      form {
        .first-row {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          .select-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex: 1;

            label {
              color: #242424;
              font-family: "Inter", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: 0.14px;
            }

            .select {
              border-radius: 8px;
              border: 1px solid #d0d5dd;
              background: #fff;

              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              position: relative;
              padding: 7px 10px;
              display: inline-block;
              min-width: 165px;

              select {
                background-color: transparent;
                border: none;
                outline: none;
                padding-right: 20px;
                font-size: 14px;
                width: 100%;
              }
            }
          }
        }

        .second-row {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            color: #242424;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.14px;
          }

          textarea,
          input {
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: #fff;
            border-radius: 6px;
            outline: none;
            padding: 10px;
          }
        }

        .button-container {
          display: flex;
          justify-content: flex-end;
          gap: 16px;

          .load-publish {
            color: #fff !important;
          }

          button {
            border-radius: 8px;
            background: #e86747;
            box-shadow: 0px 1px 2px 0px rgba(94, 81, 248, 0.12),
              0px 1px 3px 0px rgba(94, 81, 248, 0.2);
            display: flex;
            gap: 5px;
            padding: 7px 10px;
            color: white;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.video-player-container {
  .video-react {
    max-height: 300px !important;
    .video-react-video {
      height: 300px !important;
    }
  }
}
.file-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8.66px;
  border: 1px dashed #9e9e9e;
  background: rgba(250, 250, 250, 0.7);
  padding: 40px 32px;

  .upload-video-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 300px;
    margin: 5px 30px;
    padding: 10px;
    gap: 15px;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
  }
}
