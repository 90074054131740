.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: flex-start;
  padding-bottom: 0px !important;
}

.close-button {
  margin: 10px;
  width: 30px;

  cursor: pointer;
}
.lose-button-container {
  flex: 5%;
}
.video-page-container {
  display: flex;
  flex-direction: column;
  flex: 95%;
  gap: 30px;
  width: 100%;
  padding: 20px;
  span {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
}

.section-1 {
  display: flex;
  flex-direction: column;
  // flex: 3;
}

.video-container {
  width: 100%;
  height: 100%;

  video {
    border-radius: 15px;
    width: 100% !important;
    height: 100% !important;
    max-height: 90vh;
    object-fit: contain;
    display: block;
  }
}

.details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f5f5f5;
  padding: 20px 2px 20px 2px;
  text-decoration: none;
  border-radius: 10px;
  margin-bottom: 10px;
}

.details-video {
  display: flex;
  gap: 15px;
  margin-left: 10px;

  img {
    border-radius: 10px;
    width: 60px;
    height: 60px;
  }
}

.product-information {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  p {
    color: #aaaaaa;
    font-size: 14px;
    font-weight: 700;
    line-height: 20.65px;
    text-align: left;
  }

  .product-name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26.63px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}

.follow-button {
  color: white;
  font-size: 14px;
  font-weight: 700;
  // margin-right: 18px;
  padding: 5px 10px;
  border-radius: 10px;
  background: #f6552d;
}

.section-2 {
  flex: 1;
  padding-top: 10px;
  height: 90vh;
  // overflow: scroll;
}

// share modal
.copy-container {
  width: 100%;
  background-color: #f1f1f2;
  border: 1px solid rgba(22, 24, 35, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 16px 0;
  padding: 7px 18px;
  font-size: 14px;

  span {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    margin-left: 5px;
    flex-shrink: 0;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.sharing-first-row {
  display: flex;
  justify-content: space-between;

  .sharing-first-row-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }


  span {
    font-weight: bold;
    font-size: 18px;
  }
}

.sharing-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

}

@media (max-width: 1024px) {
  .video-page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .section-1 {
    margin-bottom: 0;
    flex: 0;
  }

  .product-information {
    font-size: 15px;

    .product-name {
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 350px) {
  .close-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    margin: 5px;
  }
}
@media screen and (max-width: 450px) {
  .details-video {
    display: flex;
    flex-direction: column;
    img {
      border-radius: 10px;
      width: 60px;
      height: 60px;
    }
  }

  .product-information {
    display: flex;
    flex-direction: column;
    p {
      color: #aaaaaa;
      font-size: 13px;
      font-weight: 700;
      line-height: 20.65px;
      text-align: left;
    }

    .product-name {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
    }
  }
}
