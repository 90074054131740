.box-content {
  background-color: white;
  position: relative;
  overflow-y: auto;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;

  span {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    text-align: left;
    color: #393433;
  }
  img {
    background-color: #f4f2f1;
    border-radius: 20px;
    cursor: pointer;
    padding: 8px;
  }
}
.search-box {
  background-color: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 10px;
  input {
    border: 1px solid rgb(129, 129, 129);
    padding: 15px;
    width: 100%;
    border-radius: 10px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.09371240437030792px;
    text-align: left;
    outline: none;
  }
}

.box {
  display: flex;
  // justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
  height: auto;
  max-height: 500px;
}
.no-result-box {
  display: flex;
  justify-content: center;
}
.product,
.selected {
  width: 160px;
  height: 149px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fdf3ef;
  box-shadow: 0px 4px 20px -2px #32324705, 0px 0px 5px 0px #0c1a4b0a;
  border-radius: 20px;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;

  img {
    width: 61px;
    height: 61px;
  }

  span {
    text-align: center;
  }
}

.product-content {
  img {
    border-radius: 100%;
    object-fit: fit;
  }

  .product-desc,
  .product-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selected {
  border: 1px solid #fa7959;
}
.product:hover {
  background-color: #f8e6df;
}

.buttons {
  display: flex;
  margin: 20px;
  justify-content: flex-end;
  gap: 10px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 10px;
    width: 140px;
    height: 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .cancel-button {
    border: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .next-button-active {
    background: #e86747;
    color: white;
    font-weight: 700;
    border: none;
  }
  .next-button {
    box-shadow: 1.6px 6.38px 15.95px 0px #f6552d33;
  }
}
.no-products-box {
  display: flex;
  height: 461px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #ffffff;
  margin: 20px;
  border-radius: 10px;
  span {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 600;
    white-space: normal;
    line-height: 33px;
    text-align: center;
    margin-bottom: 5px;
  }
  button {
    background: radial-gradient(
      379.37% 197.73% at -8.4% -97.73%,
      #ffe8df 0%,
      #fa7959 83.45%
    );
    padding: 5px;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    white-space: normal;
    text-align: center;
    color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 1.6px 6.38px 15.95px 0px #f6552d33;
  }
}

@media (max-width: 473px) {
  .products-pop {
    width: 100%;
    overflow: scroll;
  }
  .box {
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    overflow-y: auto;
    max-height: 300px;
  }
}
