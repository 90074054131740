.login-page {
  background-color: #f8fcff;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;

  .login-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    height: 90vh;

    @media screen and (max-width: 600px) {
      overflow-y: auto;
    }

    .login-container {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 70%;

      .login-logo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 20%;

        img {
          width: 173px;
          left: 0;
        }
      }

      .login-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;

        .login-content {
          width: 60%;

          h1 {
            font-size: 33px;
            font-weight: 700;
            text-align: left;
          }

          form {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          .MuiTextField-root {
            width: 100%;
          }

          .google {
            margin-top: 35px;
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 10;
              gap: 8px;
              border: 1px solid #03014c4c;
              border-radius: 8px;
              padding: 15px 10px;
              font-size: 13px;
              font-weight: 700;
              cursor: pointer;
              width: 100%;
            }
          }

          .have-account {
            display: flex;
            gap: 12px;
            margin-top: 30px;
            font-family: "Inter", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            color: #191918;

            button {
              color: #ea6c00;
            }
          }
        }
      }
    }
  }

  .login-right {
    min-height: 100vh;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-img {
      display: flex;
      object-fit: cover;
      max-width: 600px;
      max-height: 929px;
    }
  }
}

@media screen and (max-width: 620px) {
  .login-cont {
    display: flex;
    justify-content: center !important;
  }
  .login-content {
    max-width: 350px !important;
    width: 100% !important;
  }

  .login-section {
    margin-bottom: 30px;
  }

  h1 {
    text-align: center !important;
  }
}

@media screen and (max-width: 1060px) {
  .login-container {
    width: 100% !important;

    .login-logo {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-left: 0 !important;
    }
  }

  .login-img {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .register-text {
    display: inline !important;
  }
}

.forget-password {
  color: #d53221;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  margin-top: 10px;
  font-weight: 500;
  line-height: 120%;
}
.PrivateSwitchBase-input {
  color: #d53221 !important;
}
.remember-me {
  color: #495057;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.input-label {
  color: #495057;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 12px;
  line-height: 120%;
}
