.navigation-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.menu-wrapper {
  display: flex;
  overflow-x: auto;
  align-items: center;
  flex-grow: 1;
  margin-right: 10px;
}
.menu-container {
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  margin: 0 13px;
}
.menu-container::-webkit-scrollbar {
  display: none;
}
.menu {
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  left: 0;
  transition: left 0.3s ease;
}
.menu li {
  display: inline-block;
  padding: 7px 11px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  cursor: pointer;
}
.next-icon {
  background-color: #e4e4e4;
  border-radius: 80px;
  padding: 5px;
  margin-right: 80px;
}
.selected {
  background-color: #e86747;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.unselected {
  border-radius: 8px;
  // border: 1px solid var(--Commande-Primary-Brand-Low, #e86747);
  background-color: rgba(22, 24, 35, 0.06);
  color: #191918;
  font-size: 16px;
  font-weight: 400;
}

.top-button,
.top-button-selected {
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: 5px;
  font-size: 16px;
  border: 1px solid #e5e5e5;
  .icon {
    display: inline-block;
  }
}
.filter-wrapper-button {
  display: flex;
  justify-content: flex-end;
}
.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  padding: 10px 15px 10px 20px;
  outline: none;
  font-size: 16px;
  margin-right: 20px;
  min-width: max-content;
  background-color: #e86747;
  color: white;

  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (max-width: 900px) {
  .next-icon {
    margin: 0;
  }
  .filter-wrapper-button {
    margin-left: 0;
    justify-content: flex-start;
  }
}
.phone-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filter-button {
    padding: 8px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .phone-categories-view {
    display: flex;
    margin: 22px;
    gap: 8px;
    span:first-child {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      letter-spacing: 0.07777778059244156px;
      text-align: left;
      color: #92929d;
    }

    span {
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      letter-spacing: 0.07777778059244156px;
      text-align: left;
    }
  }
}
@media only screen and (max-width: 320px) {
  .phone-categories-view {
    display: flex;
    margin: 22px;
    gap: 8px;
    .filter-button {
      display: end;
      margin-left: 20px;
    }
  }
}
