.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(320px, 1fr));
  // grid-template-rows: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 10px 20px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: grid-template-columns 0.3s ease;
  margin-top: 20px;
}

.video-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 25px;
}

.video-details-container {
  background-color: rgb(255, 255, 255);
  display: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  height: 95%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.9s ease;
  transform: scale(1);
}

@media (max-width: 1300px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    font-size: 11px;
  }
}

@media (max-width: 760px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  // .video-details-container{
  //   height: 80%;
  //   overflow: scroll;
  // }
}

// .video-background {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-size: cover;
//   background-position: center;
//   filter: blur(20px);
//   z-index: 1;
// }

/* Define styles for small screens */
@media (max-width: 800px) {
  .video::-webkit-media-controls {
    display: none !important;
  }

  .video::-webkit-media-controls-panel {
    display: none !important;
  }

  .video::-webkit-media-controls-play-button {
    display: none !important;
  }

  .video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
}

.container-video-details {
  transition: transform 0.9s ease;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
    overflow: hidden;
  }

  .video-container {
    position: relative;
    scroll-snap-type: y mandatory;
    width: 70%;
    height: 100%;

    @media screen and (max-width: 1085px) {
      width: 60%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    .blurred-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      filter: blur(5px);
      backdrop-filter: brightness(40%);
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
      }
    }

    .arrows {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: absolute;
      right: 1rem;
      bottom: 15%;

      @media screen and (min-width: 800px) {
        top: 40%;
      }

      .product-pic {
        z-index: 10;
        // background-color: rgba(84, 84, 84, 0.5);
        border-radius: 50%;
        padding: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #dbdadb;
        padding: 1px;

        img {
          border-radius: 50%;
          width: 46px;
          height: 46px;
          object-fit: fill;
        }
      }

      .likes-div,
      .comments-div {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;

        .heart-btn {
          z-index: 10;
          // background-color: rgba(84, 84, 84, 0.5);
          border-radius: 50%;
          padding: 1px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 44px;
          height: 44px;

          svg {
            height: 35px;
            width: 35px;
          }
        }

        span {
          font-weight: 500;
          color: white;
          font-size: 14px;
        }
      }
    }

    @media screen and (min-width: 800px) {
      .arrows-btns {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .reels-btn {
        display: none;
      }

      .video-details {
        display: none !important;
      }
    }

    @media screen and (max-width: 800px) {
      .arrows-btns {
        display: none;
      }

      .product-pic {
        display: flex !important;

        img {
          border-radius: 50%;
        }
      }

      .reels-btn {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .video-details {
        display: flex;
      }
    }

    .video-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 8px;
      position: absolute;
      left: 1rem;
      bottom: 5%;
      z-index: 100;
      width: 100%;

      // background-color: rgba(84, 84, 84, 0.5);

      span {
        font-size: 15px;
        font-weight: 500;
        color: white;
        letter-spacing: 1.1px;
        font-family: "Inter", sans-serif;
        max-width: 90%;
      }

      .video-details-product {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .video {
      height: 100vh;
      width: 100%;
      object-fit: contain;
      z-index: 2;
      position: relative;
      scroll-snap-align: start;
    }
  }

  .video-info {
    padding: 20px;
    width: 30%;

    @media screen and (max-width: 1085px) {
      width: 40%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      display: none;
    }

    .product-info-wrapper {
      display: flex;
      flex-direction: column;
      padding: 16px;
      background-color: rgba(22, 24, 35, 0.03);
      border-radius: 12px;
      width: 100%;

      .video-title {
        display: inline;
        font-weight: 400;
        font-size: 15px;

        display: -webkit-box;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .product-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .product {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          object-fit: cover;
        }

        .name {
          display: flex;
          flex-direction: column;

          .product-name {
            font-size: 18px;
            font-weight: 600;
            color: #161823;
          }
        }
      }

      .follow-button {
        background-color: #f6552d;
        color: #fff;
        font-size: 18px;
        padding-inline: 15px;
        height: 40px;
        border-radius: 8px;
      }
    }

    .values {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      margin: 20px 5px;

      .likes {
        background-color: rgba(22, 24, 35, 0.03);
        padding: 7px;
        border-radius: 100%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        color: rgba(22, 24, 35, 0.75);
        font-size: 14px;
        font-weight: 600;
      }
    }


    .copy-container {
      width: 100%;
      background-color: #f1f1f2;
      border: 1px solid rgba(22, 24, 35, 0.12);
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 16px 0;
      padding: 7px 18px;
      font-size: 14px;

      span {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      button {
        margin-left: 5px;
        flex-shrink: 0;
        font-weight: bold;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.openModal {
  display: flex !important;

  @media screen and (min-width: 800px) {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .scroll-indicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    animation: bounce 1.5s infinite;
    z-index: 1000;
  }

  @keyframes bounce {
    0% {
      transform: translateX(-50%) translateY(0) rotate(180deg);
    }

    50% {
      transform: translateX(-50%) translateY(-20px) rotate(180deg);
    }

    100% {
      transform: translateX(-50%) translateY(0) rotate(180deg);
    }
  }
}

@media screen and (min-width: 800px) {
  .scroll-indicator {
    display: none !important;
  }
}

.video-infoo {
  padding: 20px;
  width: 30%;

  @media screen and (max-width: 1085px) {
    width: 40%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  .product-info {
    padding: 16px;
    background-color: rgba(22, 24, 35, 0.03);
    border-radius: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .product {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        object-fit: cover;
      }

      .name {
        display: flex;
        flex-direction: column;

        .product-name {
          font-size: 18px;
          font-weight: 600;
          color: #161823;
        }
      }
    }

    .follow-button {
      background-color: #f6552d;
      color: #fff;
      font-size: 18px;
      padding-inline: 15px;
      height: 40px;
      border-radius: 8px;
    }
  }

  .values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 5px;

    .likes {
      background-color: rgba(22, 24, 35, 0.03);
      padding: 7px;
      border-radius: 100%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      color: rgba(22, 24, 35, 0.75);
      font-size: 14px;
      font-weight: 600;
    }
  }

  .copy-container {
    width: 100%;
    background-color: #f1f1f2;
    border: 1px solid rgba(22, 24, 35, 0.12);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 16px 0;
    padding: 7px 18px;
    font-size: 14px;

    span {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      margin-left: 5px;
      flex-shrink: 0;
      font-weight: bold;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}