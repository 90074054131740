@mixin topSectionStyles() {
  display: flex;
  align-items: center;
  justify-content: center;
}

aside.user-sidebar {
  height: 100%;
  overflow-y: auto;
  width: 250px;
  // border-radius: 0px 12px 12px 0px;
  // background: linear-gradient(
  //   180deg,
  //   #fff8f7 0%,
  //   #fff9f7 39.2%,
  //   #f3f4f4 47.91%,
  //   #fbfdff 58.6%,
  //   #e8f2fb 100%
  // );
  background: #fcfdff;
}

.sidebar-container {
  width: auto;
  display: flex;
  flex-direction: column;

  .bars {
    display: flex;
    margin-left: 30px;
    color: #e26d50;

    img {
      cursor: pointer;
    }
  }
  .top-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0px 30px 30px;

    .logo {
      width: 182px;
      cursor: pointer;
    }
  }

  .navigation-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .create-video {
    position: relative;
    display: inline-block;

    .create-video-button {
      display: flex;
      align-items: center;
      gap: 10px;
      background: linear-gradient(
        101.28deg,
        #f6552d -28.82%,
        #e26d50 72.72%,
        #fec6b8 129.26%
      );
      border-radius: 29px;
      padding: 8px 15px;
      width: 174px;
      margin: 5px 10px;

      @media (max-width: 500px) {
        width: auto;
      }

      .span-title {
        font-weight: 700;
      }
    }

    .create-options-container {
      display: flex;
      width: 100%;
      position: absolute;
      margin: 0 -20px;
      z-index: 1;
      align-items: center;
      justify-content: center;
    }
  }
  .create-options {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    //gap: 10px;
    width: 100%;
    //padding: 15px 5px;
    margin: 5px 20px;
    box-shadow: 0px 0px 9.3px 0px #00000026;
    border-radius: 5px;

    .nav-link {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-align: center;
    }
  }
  .nav-link-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px 5px 15px 5px;
    &:hover {
      background-color: #fcebe6;
    }
  }
  .nav-link {
    display: flex;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    font-size: 16px;
  }
  .press-link {
    display: flex;
    cursor: pointer;
    padding: 2px 5px;
    align-items: center;
    font-size: 16px;
  }

  .span-title {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
  .icon {
    place-items: center;

    img {
      fill: red !important;
    }
  }

  .rest-items {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    margin-top: 25px;
    gap: 28px;
  }
}
.list-product-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  width: 100%;
  margin-top: 50px;
  gap: 10px;
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #1e293b;
  }
}
.list-container {
  width: max-content;
}
.add-product {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-top: 20px;
  gap: 10px;
  width: max-content;
  font-family: "Inter", sans-serif;
  font-size: 15.41px;
  font-weight: 600;
  line-height: 18.65px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  span {
    color: #e86747;
    text-decoration: underline;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
}

.scrollable {
  max-width: 210px;
  max-height: 200px;
  color: #e26d50a9;
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.show-more-product {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.product-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 10px;
  text-decoration: none;
  color: #000;

  img {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    object-fit: cover;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    margin-left: 10px;
  }
  .product-name {
    display: block;
    word-wrap: break-word;
    white-space: normal;
  }
}
.show-more-product {
  display: flex;
  align-items: center;
  margin-top: 20px;
  //margin-left: 2px;
  cursor: pointer;
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    // text-align: left;
    margin-left: 16px;
  }
}
@mixin topSectionStyles() {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1235px) {
  .user-sidebar-phone {
    position: absolute;
    z-index: 999;
    width: 250px;
    height: 100%;
    background: linear-gradient(
      180deg,
      #fff8f7 0%,
      #fff9f7 39.2%,
      #f3f4f4 47.91%,
      #fbfdff 58.6%,
      #e8f2fb 100%
    );
  }
}

@media only screen and (max-width: 500px) {
  .user-sidebar-phone {
    position: absolute;
    // margin-top: 60px;
    z-index: 999;
    width: 250px;
    height: 100%;
    background: linear-gradient(
      180deg,
      #fff8f7 0%,
      #fff9f7 39.2%,
      #f3f4f4 47.91%,
      #fbfdff 58.6%,
      #e8f2fb 100%
    );
    // .top-section {
    //   display: block;
    //   margin-left: 30px;
    //   margin-bottom: -20px;
    //   padding: 40px 0;
    // }

    .navigation-container {
      align-items: flex-start;
      // margin-left: 50px;
    }
    .rest-items {
      margin-left: 30px;
    }
  }
  .list-product-section {
    align-items: normal;
    margin-left: 30px;
    margin-top: 60px;
    gap: 20px;
  }
  .list-container {
    width: auto;
    margin-left: 0;
  }
}
.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 20px 30px;
  margin-bottom: 20px;
  // margin-top: 300px;
  // position: fixed;
}

.company-logo {
  width: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  // margin-right: 6px;
}

.company-link {
  color: #000;
  font-size: 15px;
  text-decoration: none;
  margin-right: 6px;
}
