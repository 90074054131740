.card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  // width: auto;
  width: 100%;
  // background-color: #ffffff;
  min-height: 250px;
  overflow: hidden;
  box-shadow: 0 0px 2px rgba(144, 139, 139, 0.1);
  padding: 12px;
  padding-bottom: 10px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  will-change: transform, width, height;
  border: 1px solid #f1f1f2;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.2s;

  .post-video {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    transition: opacity 0.5s ease-in-out;
    z-index: 10;
    // opacity: 0;
    // height: auto;
    // object-fit: cover;
  }

  .post-video:hover {
    // opacity: 1;
  }

  video {
    width: 100%;
    height: auto;
  }

  .imgVideo {
    width: 100%;
    height: auto;
    z-index: 10;
  }

  .blurred-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(2px);
    backdrop-filter: brightness(40%);
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }

  .card-content {
    padding: 12px 5px 0px 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
    // background-color: #ffffff;

    .product-image {
      img {
        border-radius: 50% !important;
        max-width: 34px;
        height: 34px;
        width: 34px;
        object-fit: cover;
        border: 1px solid #e86747;
      }
    }

    .product-photo {
      object-fit: contain;
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }

    .card-details-wrapper {
      width: 100%;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title-button-wrap {
        display: flex;
        flex: 1;
        min-width: 0;

        .card-title {
          font-size: 18px;
          font-weight: 500;
          color: #e86747;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          max-width: 100%;
        }

        .progress-bar-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: rgba(255, 255, 255, 0.2);

          .buffered-bar {
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
          }

          .played-bar {
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
          }
        }

        .like-button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7.5px;
          cursor: pointer;
          font-weight: bold;
          background: rgba(229, 229, 229, 0.5);
          height: 30px;
          padding: 4px;

          span {
            padding-right: 5px;
            padding-left: 5px;
            padding-bottom: 10px;
            padding-top: 5px;
            font-family: sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            margin-top: 5px;
            color: #191918;
          }

          .heart {
            margin-left: 8px;
            width: 25px;
            height: 25px;
          }
        }

        .like-button2 {
          border-radius: 32px;
          cursor: pointer;
          padding-right: 5px;
          font-weight: bold;
          height: 35px;
          margin-bottom: 5px;
          background-color: #ddd;
        }
      }
    }
  }

  .heart {
    @media screen and (max-width: 768px) {
      width: 30px !important;
      height: 30px !important;
    }
  }

  .author {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    margin: 5px 0;
    color: #aaaaaa;
    line-height: 16.94px;
    font-family: sans-serif;
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    p {
      color: #191918;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      flex: 1;
    }
  }

  .author a {
    background-color: #e86747;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    line-height: 19.36px;
    letter-spacing: -0.025em;
    text-align: left;
    padding: 2px 6px;
    margin-left: 10px;
  }

  .controls {
    position: absolute;
    top: 3%;
    left: 3%;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    opacity: 0.6;
    color: #ffffff;
  }

  .media-container {
    position: relative;
    cursor: pointer;
    width: 100%;
  }

  .media-container img,
  .media-container video {
    position: relative;
    max-height: 250px !important;
    min-height: 250px;
    width: 100%;
    border-radius: 15px;
  }

  .media-container img {
    object-fit: contain;
  }

  .media-container video {
    object-fit: contain;
  }
}