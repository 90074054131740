.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 32px 0 32px;
  padding-top: 20px;

  .header {
    color: #202020;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
}

.content {
  margin: 70px 24px 0 24px;
  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  .left-section {
    width: 20%;
    min-width: 200px;
    margin-bottom: 24px;

    .menu {
      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        cursor: pointer;
        margin: 0;
        padding: 0;

        li {
          padding: 12px;
          color: #262d33;
          font-size: 18px;
          font-weight: 500;
          list-style: none;
        }

        .selected-item {
          background-color: #fcebe6;
          border-radius: 8px;
        }
      }
    }
  }

  .right-section {
    flex-grow: 1;
    padding: 0 50px 0 0;
  }

  @media screen and (max-width: 768px) {
    .right-section {
      padding: 0;
    }
  }
}

// PORDUCT INFO
.product-info {
  width: 100%;
  .header {
    color: #202020;
    font-size: 20px;
    font-weight: 600;
  }

  .subheader {
    color: #aba8b0;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 30px;

    .label {
      color: #262d33;
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
    }

    .input {
      border: 1px solid #dcdcdc;
      padding: 14px;
      border-radius: 8px;
      outline: none;
      width: 100%;
      // margin-right: 8px;
    }

    .hr {
      background-color: rgba(171, 168, 176, 0.15);
      height: 1px;
      margin: 24px 0;
      border: none;
    }

    .btns {
      display: flex;
      margin: 20px 0;
      justify-content: flex-end;
      gap: 10px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 10px;
        width: 140px;
        height: 42px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
      .cancel {
        border: 1px solid #dcdcdc;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .next {
        background-color: #e86747;
        color: white;
        font-weight: 700;
        border: none;
      }
    }

    .more-links {
      .options {
        display: flex;

        .link {
          display: flex;
          gap: 8px;
          margin-top: 16px;
          align-items: center;
          color: #f6552d;
          text-decoration: underline;
          font-size: 16px;
          font-weight: 400;
          background: none;
          border: none;
        }
      }

      .new-item {
        width: 100%;

        .label {
          margin: 20px 0 10px 0;
        }

        .input {
          width: 90%;
        }
      }
    }
  }
}

.menu-item {
  display: flex;

  img {
    margin-right: 8px;
  }
}

// Media
.media-container {
  height: 100vh;

  .header {
    color: #202020;
    font-size: 20px;
    font-weight: 600;
  }

  .subheader {
    color: #aba8b0;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
  }

  .img-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 50px 0;

    .img-box {
      position: relative;
      height: auto;
      min-height: 100px;
      width: 130px;
      border-radius: 12px;
      border: 1px dashed var(--Neutral-n400, #94a3b8);
      background: #d9d9d9;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: fit;
        width: 100%;
        height: 100%;
      }

      .input-img {
        display: none;
      }

      .label-img {
        position: absolute;
        cursor: pointer;
        inset: 0;

        span {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        }
      }
    }

    .select-container {
      display: flex;
      gap: 12px;
      flex-direction: column;

      .btn {
        background: radial-gradient(
          266.74% 159.9% at -5.39% -50%,
          #ffd0bd 0%,
          #fa7959 100%
        );
        border-radius: 8px;
        color: white;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        max-width: 150px;
        filter: drop-shadow(2px 10px 10px rgba(246, 85, 45, 0.1));
        border: none;

        input {
          display: none;
        }
      }

      .text {
        color: #aba8b0;
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
      }
    }
  }

  .hr {
    background-color: rgba(171, 168, 176, 0.15);
    height: 1px;
    margin: 24px 0;
    border: none;
  }

  .cover-box {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 150px;
    border-radius: 12px;
    border: 1px dashed var(--Neutral-n400, #94a3b8);
    background: #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .input-img {
      display: none;
    }

    .label-img {
      position: absolute;
      cursor: pointer;
      inset: 0;

      span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
    }
  }

  .btns {
    display: flex;
    margin: 20px;
    justify-content: flex-end;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-radius: 8px;
      padding: 10px;
      width: 140px;
      height: 42px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    .cancel {
      border: 1px solid #dcdcdc;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .next {
      background: radial-gradient(
        379.37% 197.73% at -8.4% -97.73%,
        #ffe8df 0%,
        #fa7959 83.45%
      );
      box-shadow: 1.6px 6.38px 15.95px 0px #f6552d33;
      color: #f4f2f1;
      font-weight: 700;
      border: none;
    }
  }
}

.create-product-page {
  background-color: #fcfdff;
}

//new create product modal styles
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;

  height: 90%;
  outline: none;
  max-height: 90vh;
  overflow-y: auto;

  width: 70%;

  // .product-info {
  //   overflow-y: scroll;
  // }

  @media (max-width: 768px) {
    .form-inputs {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: start;
      margin-bottom: 10px;
    }

    .form-inputs input,
    .form-inputs textarea {
      width: 100%;
      // margin-left: 30px;
    }

    .form-inputs-label {
      min-width: 140px;
    }
    .auto-complete {
      width: 100% !important;
    }

    width: 95%;
  }
}

/* Additional styles... */

.form-options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: -5px;
  margin-bottom: 10px;

  .options-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;

    .option {
      display: inline-block;
      padding: 10px 15px;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      cursor: pointer;

      border-radius: 8px;
      // border: 1px solid var(--Commande-Primary-Brand-Low, #e86747);
      background-color: rgba(22, 24, 35, 0.06);
      color: #191918;
      font-size: 16px;
      font-weight: 400;
    }

    .selected {
      background-color: #e86747;
      border-radius: 8px;
      color: white;
      font-size: 16px;
      font-weight: 400;
    }
    .unselected {
      border-radius: 8px;
      // border: 1px solid var(--Commande-Primary-Brand-Low, #e86747);
      background-color: rgba(22, 24, 35, 0.06);
      color: #191918;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.error {
  color: red;
}

.form-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.form-inputs input,
.form-inputs textarea {
  width: 80%;
  margin-left: 30px;
}

.form-inputs-label {
  min-width: 140px;
}

.form-file-input {
  justify-content: space-between;
}

.product-image {
  margin-left: 30px;
  margin-right: 20px;
  max-width: 70px;
  max-height: 70px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.auto-complete {
  // width: 80% !important;
  margin-left: 30px;
}

.hiddenFileInput {
  display: none;
}

.uploadLabel {
  cursor: pointer;
  color: #e86747;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: inline-block;
  width: 100%;
}

.uploadLabel:hover {
  text-decoration: none;
}

.labelContainer {
  display: block;
  text-align: start;
}

.image_size {
  color: #475467;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
