.details {
  padding: 0 10px;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    font-weight: bold;
    color: #e86747;
    background-color: #f9f9f9;
  }

  .header {
    img {
      max-height: 263px;
      height: auto;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 593px) {
      .product-details {
        margin-left: 20px !important;
        display: flex;
        // justify-content: center !important;

        .info-left {
          margin-top: -25px !important;

          .product-info {
            .product-name {
              font-size: 25px !important;
              margin-top: 30px !important;
            }

            .product-email {
              font-size: 15px !important;
              margin-bottom: 12px;
            }
          }
        }
      }
    }

    .product-details {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      align-items: center;
      margin: 0 50px;

      .info-left {
        display: flex;
        gap: 16px;
        align-items: center;

        img {
          border-radius: 100%;
          margin-top: 20px;
          width: 110px;
          height: 110px;
          object-fit: fill;
          z-index: 60;
          fill: #fff;
          border: 4px solid #e7e7e6;
        }

        .product-info {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .product-name {
            color: #191918;
            text-align: start;
            font-family: "Inter", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          .product-email {
            color: #888;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      .info-right {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 500px) {
          .follow {
            font-size: 15px !important;
            padding: 8px 15px !important;
          }

          .website {
            font-size: 15px !important;
            padding: 8px 15px !important;
          }
        }

        .follow {
          border-radius: 5px;
          border: 1.381px solid #d9e1ec;
          background: var(--Shade-White, #fff);
          color: #21293c;
          text-align: center;
          font-family: "Inter", sans-serif;
          font-size: 19px;
          font-style: normal;
          font-weight: 700;
          line-height: 33px;
          padding: 10px 20px;
          cursor: pointer;
        }

        .website {
          text-decoration: none;
          cursor: pointer;
          padding: 10px 15px 8px 15px;
          border-radius: 8px;
          border: none;
          color: #fff;
          font-family: "Inter", sans-serif;
          font-size: 19px;
          font-style: normal;
          font-weight: 700;
          line-height: 33px;
          background: #e86747;
        }
      }
    }
  }

  .top-video-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    width: auto;

    .desc {
      color: #19202c;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      min-width: 230px;
    }

    .author {
      color: var(--Gray-Color, #aaa);
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 10px 0;

      span {
        color: rgba(246, 85, 45, 0.71);
        font-family: "Inter", sans-serif;
        font-size: 1px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.4px;
        text-transform: uppercase;
      }
    }

    .likes {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 7.22px;
      background: rgba(229, 229, 229, 0.5);
      backdrop-filter: blur(4.211719512939453px);
      max-width: 67px;
      padding: 3px 5px;
    }
  }



  .video {
    // border-radius: 15px;
    border: 1px solid #f1f1f2;
    background: #fff;
    object-fit: contain;
    // padding: 12px;
    flex: 1;
    //width: 100%;
    margin-bottom: 10px;
    width: auto;
    max-height: 400px;
    position: relative;


  }

  .video-small {
    // border-radius: 15px;
    border: 1px solid #f1f1f2;
    background: #fff;
    object-fit: contain;
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
    max-height: 400px;
    min-width: 350px;
  }

  .record {
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 70px;
      cursor: pointer;
      z-index: 3;
    }
  }

  // .video:nth-child(1) {
  //   border-radius: 15px !important;
  // }

  .video-player {
    // border-radius: 13px;
    height: auto;
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    outline: none;
  }

  @media (max-width: 1024px) {
    .videos {
      grid-template-columns: repeat(2, 1fr) !important;
      /* 2 columns */
    }
  }

  @media (max-width: 576px) {
    .videos {
      grid-template-columns: repeat(1, 1fr) !important;
      /* 1 column */
    }

    .product-details {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 40px 20px 60px;

      .info-left {
        display: flex;
        gap: 20px !important;
        align-items: flex-end;
        margin-top: -25px;

        img {
          border-radius: 100%;
          margin-top: 30px;
          width: 90px !important;
          height: 90px !important;
          object-fit: fill;
          z-index: 60;
        }

        .product-info {
          .product-email {
            margin-bottom: 10px !important;
          }
        }
      }

      .info-right {
        display: flex;
        gap: 20px;
      }
    }
  }

  .videos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 70px;
    row-gap: 30px;

    @media screen and (max-width: 700px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .video-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 400px;
      padding: 0px 12px 12px 12px;

      img {
        width: 32px;
        height: 32px;
        object-fit: fill;
        border-radius: 100%;
      }

      .video-info-right {
        width: 100%;

        .video-info-right-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 10px;

          span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #e86747;
            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 18px;
          }

          .likes {
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 7px;
            background: rgba(229, 229, 229, 0.5);
            backdrop-filter: blur(4.211719512939453px);
            max-width: 70px;
            padding: 3px 5px;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .author {
        color: var(--Gray-Color, #aaa);
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .pagi {
      background-color: #19202c !important;
    }
  }

  @media (max-width: 1024px) {
    .tabs {
      margin: 50px 0px;
    }
  }

  // tab 2
  .definition-title {
    color: #21293c;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      font-weight: 700;
    }
  }

  .definition {
    color: #4b587c;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    max-width: 750px;
  }

  .product-type {
    color: #4b587c;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid #d9e1ec;
    padding: 3px 10px;
  }

  .screenshots {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 10px;

    img {
      object-fit: cover;
      width: 100%;
      max-width: 250px;
    }
  }

  // tab3
  .rate-product {
    border-radius: 4px;
    border: 1px solid #d9e1ec;
    background: #f5f8ff;
    width: 100%;
    max-width: 800px;
    padding: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    h5 {
      color: #21293c;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      span {
        font-weight: bold;
        font-size: 16px;
        color: #21293c;
      }
    }

    .leave-rating {
      color: #4b587c;
      font-family: "Inter", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .comment-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    max-width: 780px;

    .comment-user {
      display: flex;
      gap: 15px;
      align-items: center;

      img {
        border-radius: 100%;
        object-fit: fit;
        width: 50px;
        height: 50px;
      }

      .comment-user-info {
        .comment-user-name {
          color: #21293c;
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          display: flex;
          gap: 20px;

          button {
            border: none;
            color: #ff6154;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .comment {
      color: #4b587c;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    .comment-date {
      color: #4b587c;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.create {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.cover-box {
  position: relative;
  width: 100%;
  border-radius: 12px 12px 0 0;
  background: #e7e6e6;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;

  @media screen and (max-width: 768px) {
    height: 150px;
  }

  img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }

  .input-img {
    display: none;
  }

  .label-img {
    position: absolute;
    cursor: pointer;
    inset: 0;

    span {
      position: absolute;
      right: 20px;
      bottom: 20px;
      border-radius: 30px;
      opacity: 0.3;
      background: #010101;
      color: white;
      padding: 8px 12px;
    }
  }
}

// product review
.review-page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .top-section {
    margin-top: 24px;

    .question {
      color: #21293c;
      font-size: 20px;
      font-weight: 700;
    }

    .description {
      color: #4b587c;
      font-size: 15px;
      font-weight: 400;
      margin-top: 32px;
    }
  }

  .review-box {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    background-color: #f5f8ff;
    border: 1px solid #d9e1ec;
    border-radius: 4px;

    .box {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .box-header {
        color: #21293c;
        font-size: 16px;
        font-weight: 700;
      }

      .box-description {
        color: #4b587c;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .rating-container {
    display: flex;
    justify-content: space-between;

    .rating-left {
      display: flex;
      flex-direction: column;

      .rating-value {
        font-size: 32px;
        font-weight: 700;
        color: #2a85ff;
      }

      .based {
        font-size: 16px;
        font-weight: 500;
        color: #4b587c;
      }
    }

    .rating-right {
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-end;
      align-items: center;

      .rating-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .ratings {
          color: #4b587c;
          font-weight: 500;
          font-size: 16px;
          width: 80px;
          text-align: right;
        }
      }
    }
  }
}

// share modal
.copy-container {
  width: 100%;
  background-color: #f1f1f2;
  border: 1px solid rgba(22, 24, 35, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 16px 0;
  padding: 7px 18px;
  font-size: 14px;

  span {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    margin-left: 5px;
    flex-shrink: 0;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.sharing-first-row {
  display: flex;
  justify-content: space-between;

  .sharing-first-row-left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }


  span {
    font-weight: bold;
    font-size: 18px;
  }
}

.sharing-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

}

// product comments
hr {
  color: #d9e1ec;
}

.reviews-length {
  color: #21293c;
  font-size: 24px;
  font-weight: 700;
}

.user-review {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .user-review-wrapper {
    display: flex;
    gap: 15px;

    img {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      object-fit: cover;
    }

    .user-name {
      color: #21293c;
      font-weight: 600;
      font-size: 16px;
    }

    .user-role {
      color: #4b587c;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .user-comment {
    color: #4b587c;
    font-weight: 400;
    font-size: 17px;
  }

  .comment-date {
    color: #4b587c;
    font-weight: 400;
    font-size: 14px;
  }
}

.view-more {
  color: #21293c;
  background: #fff;
  border: 1px solid #d9e1ec;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
}

.comment {
  color: #3e3232;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}

.comment-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;

  textarea {
    background: transparent;
    border: none;
    width: 100%;
    border-radius: none;
  }

  textarea:focus {
    outline: none;
  }

  .send-comment {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      background-color: #fb6d4a;
      padding: 8px;
      border-radius: 8px;
      color: white;
      font-weight: 600;
      display: flex;
      gap: 10px;

      img {
        margin-top: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }
}