.primary-button {
  background: #e86747;
  filter: drop-shadow(2px 10px 10px rgba(246, 85, 45, 0.1));

  border: none;
  padding: 12px 18px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  outline: none;
}

.button-container {
  background: rgba(95, 52, 245, 0.25);
  filter: blur(20.840038299560547px);
}
