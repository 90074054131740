@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color: orange !important;
}

.player-video video {
  width: 100% !important;
  height: 100%;
  max-height: 700px;
  /* padding: 20px; */
}

.video-player {
  width: 100% !important;
  height: 100%;
  background-color: #edeef4;
}

.MuiCircularProgress-root {
  color: #ea6443 !important;
}

.MuiCircularProgress-root.white {
  color: white !important;
}

.MuiSlider-root {
  color: #232222bd !important;
}

.player-video .video-player {
  width: 100% !important;
  max-height: 1100px !important;
}

.video-react-video {
  max-height: 1100px !important;
}

/* layout */
.layout {
  display: flex;
  height: 100vh;
}

.main-content {
  /* overflow-y: auto; */
  height: 100vh;
  overflow-x: hidden;
  flex: 1;
  width: 100%;
}
/* ++++++++ */

/* product details*/
.tab {
  font-size: 18px !important;
}
.tab.Mui-selected {
  background-color: transparent !important;
  color: #f6552d !important;
  font-weight: 600;
  outline: none !important;
}
/* pagiantion */
.pagi .MuiPaginationItem-previousNext[aria-label="Go to previous page"] {
  background-color: #d5d5dc;
  border-radius: 8px;
  color: white;
}
.pagi .MuiPaginationItem-previousNext[aria-label="Go to next page"] {
  background-color: #fb6d4a;
  border-radius: 8px;
  color: white;
}
.pagi .MuiPaginationItem-page {
  color: #92929d;
  font-size: 14px;
  font-weight: 400;
}
.pagi .Mui-selected {
  background-color: transparent !important;
  color: #fb6d4a;
  font-size: 14px;
  font-weight: bold;
}
/* ++++++++++++ */
/* PRODUCT POPUP */
.productPopUp_box-content__hFMtA {
  width: 90% !important;
}

/* search text hidden */
.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  display: none !important;
}

/* inputs mui */
.MuiOutlinedInput-root,
.MuiInputBase-input {
  width: 100% !important;
}

/* pagination */
.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #e86747 !important;
  color: #fff !important;
  border: none !important;
}

.css-1y7coo4-MuiButtonBase-root-MuiPaginationItem-root {
  border: 1px solid #e0e0e0 !important;
}

/* modal */
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.MuiDialog-paper {
  overflow: hidden !important;
}
