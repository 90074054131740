#formButton {
  background-color: #fb6d4a !important;
}
.record {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // height: 100vh;
  // justify-content: center;

  .recordOn {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .buttons {
      display: flex;
      flex-direction: row;

      .create {
        background: linear-gradient(
          101deg,
          #f6552d -28.82%,
          #e26d50 72.72%,
          #fec6b8 129.26%
        );
        color: white;
        font-weight: bold;
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}

.bubble-container {
  border-radius: 100% !important;
  width: 50px !important;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px !important;
}

.buttons-on-big {
  left: 280px !important;
}

.record-buttons {
  position: absolute;
  z-index: 10000000000000;
  bottom: 8px;
  left: 200px;
  width: max-content;
  min-width: fit-content;
  border-radius: 100px;
  background-color: #212121;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  border: 4px solid rgba(255, 255, 255, 0.6);
  @media screen and (max-width: 473) {
    .record-buttons {
      left: 0;
    }
  }
  .bubble {
    background-color: #212121;
    bottom: 8px;
    left: 0px;
    border-radius: 100%;
    cursor: pointer;
    width: 30px !important;
    padding: 3px;
    display: inline;
    max-width: 40px !important;

    user-select: none; /* Disables text selection */
    -webkit-user-drag: none; /* Prevents image dragging in Safari and Chrome */
    -moz-user-drag: none; /* Prevents image dragging in Firefox */
    -ms-user-drag: none; /* Prevents image dragging in IE/Edge */
    user-drag: none; /* Standard property to prevent image dragging */
  }

  .all-buttons {
    display: flex;
    align-items: center;
    gap: 12px;

    .stop-icon {
      padding-right: 0 !important;
    }

    span,
    img {
      cursor: pointer;
      width: 100%;

      user-select: none; /* Disables text selection */
      -webkit-user-drag: none; /* Prevents image dragging in Safari and Chrome */
      -moz-user-drag: none; /* Prevents image dragging in Firefox */
      -ms-user-drag: none; /* Prevents image dragging in IE/Edge */
      user-drag: none; /* Standard property to prevent image dragging */
    }

    .timer {
      color: #e5e5ff;
    }

    .hr {
      color: #e5e5ff;
    }

    .small-circle {
      background-color: #e5e5ff;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      cursor: pointer;

      span {
        color: transparent;
      }
    }

    .big-circle {
      background-color: #e5e5ff;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      cursor: pointer;

      span {
        color: transparent;
      }
    }
  }
}

.react-draggable {
  position: absolute;
  bottom: 2rem !important;
  left: 2rem !important;
}

#videoElement {
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10000000;
}

.draggable-container {
  position: absolute;
  z-index: 9999;
  left: 0rem;
  bottom: 0rem;
}

.draggable-container .draggable-content {
  position: absolute;
  width: 100%;

  video {
    max-width: 333px !important;
  }
}

.small-camera {
  width: 222px;
  height: 222px;
}

.big-camera {
  width: 333px;
  height: 333px;
}

.remove-full-screen {
  display: none;
}

.camera-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  #videoElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .record-buttons {
    img {
      padding-right: 0px;
    }
  }
}

.recordedVideoContainer {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  max-width: 800px;

  #recordedVideo {
    width: 100%;
    height: auto;
    display: block;
  }
}

.MuiBox-root {
  outline: none;
  position: absolute;
  top: 5%;
  right: 3%;
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 0 20px;
  border-radius: 10px;

  .logo {
    margin-top: 20px;
    max-width: 150px;
  }

  .div {
    margin: 20px 0;

    form {
      display: flex;
      flex-direction: column;
      gap: 12px;

      span {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 500;
      }

      .span {
        margin-top: 12px;
      }

      .camera-options {
        display: flex;
        align-items: center;
        gap: 8px;

        .mic-img-container {
          background-color: #fcebe6;
          padding: 10px;
          border-radius: 8px;
        }
      }

      .advanced-options {
        color: #212121;
        font-size: 13px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        text-align: center;
        background: transparent;
        padding: 0;
        margin: 8px 0;
        cursor: pointer;
      }

      .advanced-options:hover {
        background-color: transparent;
      }

      .ao {
        margin-top: 10px;
      }

      .advanced-container {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        span {
          font-weight: 500;
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 20px;

          input {
            opacity: 0;
            width: 40px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;

            &:before {
              position: absolute;
              content: "";
              height: 14px;
              width: 14px;
              left: 3px;
              bottom: 3px;
              background-color: white;
              transition: 0.4s;
              border-radius: 50%;
            }
          }

          input:checked + .slider {
            background-color: #ff623e;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #ff623e;
          }

          input:checked + .slider:before {
            transform: translateX(20px);
          }

          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
      }

      .start {
        border-radius: 8px;
        background-color: #e86747;
        color: white;
        width: 100%;
        font-weight: 500;
        font-size: 17px;
        padding: 12px 0;
        margin-top: 0;
        border: none;
        cursor: pointer;
      }
    }
  }
}

// options
.options-container {
  position: relative;
  display: inline-block;
  width: 100%;

  .select-box {
    .selected-item {
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 8px;
      padding: 8px 12px;
      border: 1px solid #d7d5dd;
      cursor: pointer;
      justify-content: space-between;

      .devices {
        padding-left: 5px;
      }

      .select-inside {
        display: flex;
        gap: 8px;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      svg {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        transition: all 0.2s;
      }

      .rotate-arrow {
        transform: rotate(180deg);
      }
    }

    .options {
      position: absolute;
      background-color: white;
      border-radius: 4px;
      border: 1px solid #f6552d;
      overflow: hidden;
      z-index: 10;
      width: 100%;
      margin-top: 4px;

      .option {
        display: flex;
        align-items: center;
        padding: 8px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }

        .camera-icon {
          margin-left: -3px;
        }

        .screen-icon {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }

      .option:hover {
        background-color: #f4f5f5;
      }
    }
  }
}

.counter {
  position: absolute;
  color: white;
  font-weight: bold;
  background-color: #ff623e;
  width: 8rem;
  height: 8rem;
  z-index: 99999;
  border-radius: 100%;
  left: 45%;
  top: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 50px;
  }
}
