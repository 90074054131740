.signup-page {
  background-color: #f8fcff;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;

  .signup-container {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    max-width: 1440px;

    .signup-left {
      min-height: 100vh;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .signup-img {
        display: flex;
        object-fit: cover;
        max-width: 600px;
        max-height: 929px;
        // height: 100vh;
      }
    }

    .signup-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      height: 100%;

      .logo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        margin-left: 16%;

        img {
          width: 173px;
          left: 0;
        }
      }

      .signup-right-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .right-content {
          width: 100%;
          padding: 12px;

          h1 {
            font-size: 33px;
            font-weight: 700;
            text-align: left;
          }

          .form-content {
            width: 100%;
            margin-top: 15px;
            display: flex;
            flex-direction: column;

            .form-row-1 {
              display: flex;
              gap: 16px;
              width: 100%;

              .form-input {
                width: 100%;
              }

              .input-label {
                color: #495057;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
              }
            }

            .form-row {
              display: flex;
              gap: 16px;
              margin-top: 16px;
              width: 100%;

              .form-input {
                width: 100%;

                .date-picker {
                  height: 44px;
                }
                .date-picker:focus {
                  outline: none;
                }
              }

              .input-label {
                color: #495057;
                font-family: "Inter", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
              }
            }
          }

          .google {
            margin-top: 30px;
            display: flex;
            align-items: center;
            gap: 16px;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 10;
              gap: 8px;
              border: 1px solid #03014c4c;
              border-radius: 8px;
              padding: 15px 10px;
              font-size: 13px;
              font-weight: 700;
              cursor: pointer;
              width: 100%;
            }
          }

          .have-account {
            display: flex;
            gap: 12px;
            margin-top: 30px;
            font-family: "Inter", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            color: #191918;

            button {
              color: #ea6c00;
            }

            @media screen and (max-width: 768px) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .signup-img {
    display: none !important;
  }

  .signup-page {
    display: block !important;
  }

  .signup-container {
    margin: 50px 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .signup-right {
      width: 90% !important;

      .logo {
        justify-content: center !important;
        align-items: center !important;
        margin-left: 0 !important;
      }
    }

    .right-content {
      h1 {
        text-align: center !important;
      }

      .form-content {
        .form-row-1 {
          display: flex;
          flex-direction: column !important;

          .form-input {
            width: 100% !important;
          }
        }
        .form-row {
          display: flex;
          flex-direction: column !important;

          .form-input {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media (min-width: 620px) {
  .right-content {
    width: 70% !important;
  }
}

@media (min-width: 768px) {
  .register-text {
    display: inline !important;
  }
}
