.layout .main-content {
  /* overflow-y: auto; */
  flex: 1;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 50px;
  background-color: #fcfdff;
}

@media screen and (max-width: 768px) {
  .layout {
    margin-bottom: 100px;
  }

  .layout .main-content {
    padding-bottom: 100px;
  }
}
