html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevents body from scrolling */
}

.main-content {
  height: calc(
    100vh - 60px
  ); /* Adjust based on the height of the bottom menu */
  overflow-y: auto; /* Makes the main content scrollable */
}

.scrollable-content {
  padding: 20px;
  /* Your other content styles */
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000; /* Ensure it is above other content */
}

.menu-item,
.menu-item-active {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item-active img {
  filter: brightness(0) saturate(100%) invert(39%) sepia(88%) saturate(658%)
    hue-rotate(341deg) brightness(100%) contrast(98%);
}

.menu-item img {
  filter: none;
}

.menu-item span,
.menu-item-active span {
  font-size: 12px;
  margin-top: 5px;
  color: #000;
}
