.header {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0;
  // z-index: 1000;
  // background-color: #fcfdff;
  // width: 100%;
  .container-view {
    // display: flex;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: flex-start;
    transition: height 0.3s ease;
    height: auto;
    min-height: 100px;
    overflow: hidden;
    flex: 0 1 auto;
    flex-shrink: 0;
    position: relative;
    display: -webkit-flex;
    -webkit-flex-direction: column;

    will-change: height;

    // background-color: #f5fafe;
    // background: linear-gradient(
    //   90deg,
    //   #fff8f7 0%,
    //   #fff9f7 39.2%,
    //   #f3f4f4 47.91%,
    //   #fbfdff 58.6%,
    //   #e8f2fb 100%
    // );
    .header-left {
      display: flex;
      gap: 30px;
      align-items: center;

      .record {
        cursor: pointer;
      }

      .popover {
        position: absolute;
        background-color: white;
        box-shadow: "0px 0px 29.143px 0px rgba(191, 200, 208, 0.50)";
        border-radius: 12px;
        width: 160px;
        padding: 5px;
        top: 2.5rem;
        right: 7rem;
        z-index: 99999;
        margin-top: 20px;
        margin-right: 20px;
      }

      .open-record {
        display: block;
      }
      .hide-record {
        display: none;
      }
    }
  }

  .ai-matchmaking {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .search-container {
    display: flex;
    align-items: center;

    .search-bar-container {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      margin-left: 10px;
      -webkit-appearance: none;
      border-radius: 0;

      .input-wrapper {
        display: flex;
        align-items: center;
        min-width: 700px;
        height: 100%;
        flex-grow: 1;
        padding: 7px 15px;

        input {
          -webkit-appearance: none;
          appearance: none;
        }

        input {
          width: 100%;
          margin-left: 5px;
          color: #ada7a7;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }
      }

      .search-bar-box {
        display: flex;
        flex-direction: column;
        background-color: #fffdfd;
        border: 1px solid #e86747;
        border-radius: 10px;

        .search-bar-history-btn {
          outline: none;
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            color: #e86747;
            font-family: "Inter", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .search-bar-bottom {
          padding: 12px 15px;
          background-color: #fcedea;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .search-bar-bottom-left {
            display: flex;
            align-items: center;
            gap: 12px;

            .search-bar-bar {
              stroke-width: 1px;
              color: #e86747;
              background-color: #e86747;
              height: 15px;
              width: 1.5px;
            }
          }

          .search-bar-reset {
            color: #e86747;
            font-family: "Inter", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }
        }

        .search-history-container {
          display: none;
          opacity: 0;
          flex-direction: column;
          margin: 5px 20px;
          border-top: 1px solid #e9e9e9;
          padding: 10px 0;

          .search-history {
            display: flex;
            flex-direction: column;
            gap: 7px;

            .history-item {
              display: flex;
              justify-content: space-between;

              p {
                color: #272727;
                font-family: "Inter", sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
              }
            }
          }

          .history-tags {
            display: flex;
            gap: 10px;
            margin-top: 15px;
            max-width: 90%;
            overflow-x: auto;

            .tag {
              min-width: max-content;
              border-radius: 5px;
              border: 1px solid #d0d5dd;
              background: #fff;
              padding: 3px 6px;
              display: flex;
              gap: 3px;
              align-items: center;
              justify-content: center;

              span {
                color: #344054;
                font-weight: 500;
                font-size: 12px;
              }
            }
          }
        }

        // .input-wrapper:focus-within + .tag,
        // .input-wrapper:focus-within + .search-history-container {
        //   display: flex;
        //   opacity: 1;
        // }

        // .search-bar-bottom {
        //   // display: none;
        //   // opacity: 0;
        //   transition: opacity 0.3s ease;
        // }

        // .input-wrapper:hover + .search-bar-bottom,
        // .input-wrapper:focus-within + .search-bar-bottom {
        //   display: flex;
        //   opacity: 1;
        // }
      }

      #search-icon {
        color: #888;
        font-size: 1.5rem;
        margin-right: 8px;
      }

      input {
        border: none;
        outline: none;
        font-size: 15px;
        padding: 6px;
        color: #333;
      }

      div {
        font-weight: bold;
        color: #555;
      }
    }
  }

  .notif-avatar-container {
    display: flex;
    align-items: center;
    margin-right: 30px;
    gap: 15px;

    span {
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin-left: 10px;
    }

    .avatar {
      font-size: large;
      font-weight: bold;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: white;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #eaeaea;

      img {
        border-radius: 50%;
        object-fit: cover;
        width: 50px;
        height: 50px;
      }
    }
  }

  .header-connect-wrapper {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-left: auto;

    @media screen and (min-width: 1200px) {
      margin-left: 32px;
      padding: 0;
    }

    .btns-wrapper {
      display: flex;
      gap: 16px;
      margin-left: 16px;

      @media screen and (min-width: 1200px) {
        margin-right: 16px;
      }
    }
  }

  .sign-up-button {
    background-color: #f6f6f5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    white-space: nowrap;
    border-radius: 8px;
    color: #e86747;
    border: 1px solid #e86747;
    font-size: 16px;
    font-weight: 400px;
    padding: 10px 24px;
  }

  .login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    white-space: nowrap;
    border-radius: 8px;
    color: #fff;
    background-color: #e86747;
    font-size: 16px;
    font-weight: 400px;
    padding: 10px 24px;
  }
}

@media only screen and (max-width: 1120px) {
  .header {
    .container-view {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 15px;
      width: 100%;

      .header-left {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: 0 !important;
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      width: 90%;

      .search-bar-container {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        margin-left: 0px;
        width: 100%;

        .search-bar-div {
          width: 100%;
        }

        .input-wrapper {
          display: flex;
          align-items: center;
          min-width: 100% !important;
          height: 100%;
          flex-grow: 1;
          padding: 7px 15px;

          input {
            width: 100%;
            margin-left: 5px;
            color: #ada7a7;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
    .notif-avatar-container {
      margin-right: 15px;
    }
  }

  .container-view {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  // .sign-up-button {
  //   max-width: 100px;
  // }

  // .login-button {
  //   max-width: 100px;
  // }
}

.search-expand {
  display: block;
  z-index: 9999;
  max-height: 150px;
  /* Set the max height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling */
  margin-top: 10px;
  /* Space between the search bar and the questions */
  padding: 10px;
  /* Optional padding for better aesthetics */
  border: 1px solid #ccc;
  /* Optional border */
  border-radius: 4px;
  /* Optional rounded corners */
  background-color: #f9f9f9;
  /* Optional background color */
}

.calendar-list {
  display: flex;
  align-items: center;
}

.calendar {
  display: flex;
  flex-direction: column;
}

// History Modal
.products-pop {
  .box-content {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    width: 720px;

    @media screen and (max-width: 768px) {
      width: 90%;
    }

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      background: #fff8f0;
      box-shadow: 0px 0px 23.2px 0px rgba(226, 212, 212, 0.12);
      padding: 20px 30px;

      span {
        color: #e86747;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
      img {
        background-color: #f4f2f1;
        border-radius: 20px;
        cursor: pointer;
        padding: 8px;
      }
    }

    .list-box {
      padding: 0px 30px 30px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-left {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .item-text {
            color: #3b3b3b;
            font-family: "Inter", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
          }

          .item-date {
            color: #b8b8b8;
            font-family: "Inter", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
          }
        }

        .item-button {
          border-radius: var(--radius-sm, 6px);
          background: #f5f5f5;
          padding: 5px;
        }
      }
    }
  }
}

@supports (-webkit-appearance: none) {
  .header {
    height: fit-content !important;
  }
}
