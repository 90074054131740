/* Main container to allow scrolling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Use the full viewport height */
  overflow-y: auto; /* Enable vertical scroll */
}

.logo {
  width: 182px;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    // display: none;
  }
}

.section-zero {
  width: 100%;
  padding: 40px 200px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    padding: 40px 60px 40px 50px;
  }

  @media screen and (max-width: 628px) {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 40px;
  }
}

.section-one {
  width: 100%;
  padding: 20px 200px 120px 200px;

  @media screen and (max-width: 1200px) {
    padding: 20px 60px 120px 50px;
  }

  @media screen and (max-width: 800px) {
    padding: 20px 40px 120px 40px;
  }
}

.support-header-connect-wrapper {
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-left: 0px;

  @media screen and (min-width: 1200px) {
    margin-left: 32px;
    padding: 0;
  }

  .support-btns-wrapper {
    display: flex;
    gap: 16px;
  }
}

.support-sign-up-button {
  background-color: #f6f6f5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  white-space: nowrap;
  border-radius: 8px;
  color: #e86747;
  border: 1px solid #e86747;
  font-size: 16px;
  font-weight: 400px;
  padding: 10px 24px;
}

.support-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  white-space: nowrap;
  border-radius: 8px;
  color: #fff;
  background-color: #e86747;
  font-size: 16px;
  font-weight: 400px;
  padding: 12px 20px;

  button {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    display: flex;
    gap: 10px;

    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
}

.support-title-container {
  width: 100%;
  margin: 50px 0px 50px 0px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1000px) {
    margin: 0px 0px 50px 0px;
    display: block;
  }
}

.social-media-oval-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 100px;

  @media screen and (max-width: 1000px) {
    flex-direction: row;
    width: 280px;
    margin: 30px 0px 30px;
  }
}

.social-media-oval-container img {
  width: 40px;
  cursor: pointer;
  height: auto;
}

.support-title {
  font-size: 3.5em;
  font-weight: 700;
  font-family: "Inter";
  line-height: 1.2em;
  width: 60%;
  @media screen and (max-width: 1000px) {
    width: 100%;
    font-size: 2.5em;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 2em;
    line-height: 1.3em;
  }
}

.form-container {
  width: 100%;
  box-sizing: border-box;
}

.support-input {
  width: 100%;
  padding-left: 5px;
}
.support-input:focus {
  outline: none;
  border: none;
}
.support-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
  height: fit-content;
  padding-bottom: 3px;
  border-bottom: solid gray 0.6px;
}

.support-label {
  font-size: 20px;
  font-weight: 400;
  font-family: "Inter", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.f1-container,
.f3-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 200px 80px 200px;
  @media screen and (max-width: 1200px) {
    padding: 80px 60px 80px 60px;
  }
  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 800px) {
    padding: 80px 40px 80px 40px;
  }
}

.f1-assist-txt {
  font-weight: 700;
  font-size: 2em;
  font-family: "Inter";
  line-height: 1.2em;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.f1-assist-txt-container {
  width: 25%;
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 50px;
  }
}
.f1-assist-contact {
  font-size: 1em;
  font-family: "Inter";
  margin-bottom: 10px;
}
.f1-info-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 50px;
  }
}

.f1-info-section p {
  color: #000;

  /* Sub Heading */
  font-family: "Inter";
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.f1-info-section div {
  color: #000;
  /* Paragraph */
  font-family: "Inter";
  font-size: 1em;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 160% */
}

.f2-container {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 60px 200px 60px 200px;
  background-color: #e86747;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    display: block;
    padding: 40px 50px 40px 50px;
  }

  @media screen and (max-width: 800px) {
    padding: 40px;
  }
}

.subscribe-container {
  width: 50%;
  color: #ffffff;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.f2-subscribe-txt {
  font-weight: 700;
  font-size: 30px;
  font-family: "Inter";
}

.f2-subscribe-sub-txt {
  font-size: 12px;
  font-family: "Inter";
  color: white;
}
.f3-container {
  background-color: black;
}

.f3-logo img {
  width: 200px;
  height: auto;
}

.f3-social-media-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.f3-social-media {
  display: flex;
  gap: 12px;
  width: 30%;
  margin: 20px 0px 20px -10px;
}

.f3-social-media-txt p {
  color: #ccc;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-bottom: 10px;
}

.f4-container {
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  width: 100%;

  p {
    color: #fff;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    span {
      font-weight: 700;
    }
  }
}
