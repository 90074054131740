.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  flex-wrap: wrap;

  .left-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    h1 {
      font-size: 61px;
      font-weight: bold;
    }

    p {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 12px;
    }
  }

  img {
    object-fit: contain;
    max-height: 770px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 8px;

    .left-section {
      margin-bottom: 40px;

      h1 {
        font-size: 41px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}
