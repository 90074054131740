.video-page {
  overflow-x: hidden;
  .video-container {
    width: 100%;
    min-height: 632px;
    padding: 30px;

    position: relative;

    .video-player {
      width: 100% !important;
      height: 100%;
      background-color: #edeef4;

      .video-react-video {
        width: 100% !important;
      }
    }

    .edit-buttons {
      position: absolute;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #f1f1f1;
      padding: 12px;
      z-index: 100000;
      display: flex;
      flex-direction: column;
      gap: 20px;
      right: 20px;
      top: 400px;

      span {
        cursor: pointer;
      }
    }
  }

  .video-info {
    margin: 32px;
    padding: 30px 24px;
    background-color: #f8f9fb;
    border-radius: 6px;

    .info-header {
      display: flex;
      gap: 20px;
      align-items: center;

      .avatar-container {
        img {
          width: 62px;
          height: 62px;
          object-fit: fill;
          border-radius: 100%;
        }
      }

      .info-user-name {
        display: flex;
        flex-direction: column;
        gap: 3px;

        .video-name {
          color: #111;
          font-family: "Inter", sans-serif;
          font-size: 28px;
          font-weight: 700;
          letter-spacing: -0.714px;
        }

        .user-name {
          color: rgba(15, 23, 42, 0.5);
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .form-container {
      background: #fff;
      padding: 17px 17px 30px 17px;
      width: 100%;
      border-radius: 5px;
      margin-top: 22px;

      form {
        label {
          color: #242424;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.14px;
        }

        .first-row {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 30px;
          .select-container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            flex: 1;

            label {
              color: #242424;
              font-family: "Inter", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              letter-spacing: 0.14px;
            }

            .select {
              border: 1px solid var(--Gray-300, #d0d5dd);
              background-color: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              position: relative;
              padding: 7px 10px;
              display: inline-block;
              min-width: 165px;
              border-radius: 5px;

              select {
                background-color: transparent;
                border: none;
                outline: none;
                padding-right: 20px;
                font-size: 14px;
                width: 100%;
              }
            }
          }
        }

        .second-row {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            color: #242424;
            font-family: "Inter", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.14px;
          }

          textarea,
          input {
            border-radius: 6px;
            outline: none;
            padding: 10px;
            border: 1px solid var(--Gray-300, #d0d5dd);
            background-color: #fff;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        .button-container {
          display: flex;
          justify-content: flex-end;
          gap: 16px;

          .load-publish {
            color: #fff !important;
          }

          button {
            border-radius: 8px;
            border: none;
            background: #e86747;
            box-shadow: 0px 1px 2px 0px rgba(94, 81, 248, 0.12),
              0px 1px 3px 0px rgba(94, 81, 248, 0.2);
            display: flex;
            gap: 5px;
            padding: 7px 10px;
            color: white;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.video-player-container {
  .video-react {
    max-height: 300px !important;
    .video-react-video {
      height: 300px !important;
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .edit-buttons {
    top: 300px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1300px) {
  .edit-buttons {
    top: 200px !important;
  }
}

@media screen and (max-width: 1200px) {
  .video-container {
    min-height: fit-content !important;
  }
}

@media screen and (max-width: 600px) {
  .edit-buttons {
    top: 100px !important;
  }
}
