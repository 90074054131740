.container {
  padding: 0 25px 30px 25px;

  .header {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 30px;
  }

  .products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 21px;
    margin-bottom: 25px;

    .product {
      border: 1px solid #f1f1f2;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      background-color: white;
      border-radius: 15px;
      padding: 12px;
      padding-bottom: 0;

      .video-player-container {
        width: 100%;
        // overflow: hidden;
        height: 200px;
      }

      img,
      video {
        position: relative;
        height: 200px !important;
        // min-height: 200px;
        width: 100%;
        border-radius: 15px;
      }

      img {
        object-fit: contain;
      }

      video {
        object-fit: contain;
      }

      .product-info {
        background-color: transparent;
        padding: 15px 10px;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
        display: flex;
        justify-content: space-between;

        .product-info-left {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;

          .product-image {
            width: 40px;
            height: 40px !important;
            min-width: 40px;
            min-height: 40px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            .image {
              width: 40px;
              height: 40px;

              img {
                width: 40px;
                height: 40px !important;
                min-height: 40px !important;
                object-fit: cover;
              }
            }
          }
        }

        .product-desc {
          max-width: 180px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          color: #161616;
          font-size: 14px;
          font-weight: 500;
        }

        .product-title {
          color: #e86747;
          font-size: 16px;
          font-weight: 500;
          font-family: Inter, sans-serif;
          margin-top: 5px;
        }

        .button-get {
          background: #e86747;
          color: white;
          border-radius: 8px;
          width: 60px;
          font-size: 14px;
          font-weight: 400;
          padding: 5px 10px;
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .products {
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
  }

  @media (max-width: 760px) {
    .products {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .no-data {
    display: block;
    margin: auto;
    margin-top: 10%;

    img {
      display: block;
      margin: auto;
      margin-top: 10%;
    }

    p {
      text-align: center;
      color: #fb6d4a;
      font-family: "Poppins";
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      text-transform: capitalize;
    }
  }
}

.imgVideo,
.video {
  position: relative;
  z-index: 10;
}

.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  backdrop-filter: brightness(40%);
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}

.products-list::-webkit-scrollbar {
  display: none;
}

.products-list {
  display: flex;
  gap: 50px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 80px 20px;

  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-decoration: none;

    .product-img-wrapper {
      border-radius: 100%;
      border: 1px solid #dfdfdf;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;

      .product-img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
      }

      img {
        border-radius: 50%;
      }
    }

    .my-product-name {
      color: #000;
      text-align: center;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.video-player.active {
  filter: none;
}

.video-player {
  border-radius: 8px;
  overflow: hidden;
  // height: 100%;
  height: 200px;

  cursor: pointer;
  position: relative;

  // .video-duration {
  //   position: absolute;
  //   top: 15px;
  //   right: 15px;
  //   background: #e86747;
  //   color: white;
  //   padding: 5px 10px;
  //   border-radius: 8px;
  //   font-size: 14px;
  //   z-index: 3;
  // }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}